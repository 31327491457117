import React from "react";
import Subscribe from "../subscribe";
import BlogBanner from "./blogBanner";
import BlogSlider from "./blogSlider";

const Blog = () => {
  return (
    <>
      <BlogBanner />
      <BlogSlider />
      <Subscribe />
    </>
  );
};

export default Blog;
